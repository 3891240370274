export default [{ label: 'Vinculação', field: 'vinculacao' },
  { label: 'Negociação', field: 'negociacao' },
  { label: 'Modalidade', field: 'modalidade' },
  { label: 'N° Conta', field: 'identificacao' },
  { label: 'Situação', field: 'situacao', type: 'custom' }, // regular ou irregular
  { label: 'Parcela', field: 'totalParcelas', tdClass: 'alinhar-coluna' },
  { label: 'Vencimento', field: 'mesUltimaParcela' },
  { label: 'Parcelas em atraso', field: 'parcelasEmAtraso', type: 'custom' },
  { label: 'Consultado em', field: 'ultimaExecucao' },
]
