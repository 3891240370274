<template>
  <div>
    <parcelamento-base
      :filtros-rapidos-custom="filtrosRapidos"
      :filtros-custom="filtros"
      colunas-custom
      :novas-colunas="colunas"
      :titulo="'Empresa: ' + titulo"
      place-holder="Buscar por: Negociações, Modalidade, Nº Conta"
    >
      <template
        slot="colunasCustom"
        slot-scope="{props} "
      >
        <div
          v-if="props.column.field === 'parcelasEmAtraso'"
          class="text-center"
        >
          <b-badge
            :variant="props.row['parcelasEmAtraso'] ? 'light-danger' : 'light-success'"
            style="margin-bottom: 5px;"
          >
            {{ props.row['parcelasEmAtraso'] ? 'Sim' : 'Não' }}
          </b-badge>
        </div>
      </template>
    </parcelamento-base>
  </div>
</template>

<style>

</style>

<script>
import colunas from './colunas'

export default {
  name: 'ParcelamentoPgfnEmissao',
  components: {
    ParcelamentoBase: () => import('../../../ParcelamentoView.vue'),
  },
  data() {
    return {
      colunas,
      filtros: [{ colunas: ['Negociacao', 'Modalidade', 'NumeroGrid'], valor: null }, { colunas: ['Empresa'], valor: this.$route.params.id }],
      filtrosRapidos: [
        {
          id: 'StatusEmAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Em atraso',
          valor: '2',
        },
        {
          id: 'StatusSemAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Em dia',
          valor: '1',
        },
        {
          id: 'DeferidaConsolidada',
          colunas: ['Situacao'],
          label: 'Deferida e consolidada',
          valor: '1',
        },
        {
          id: 'EncerradoLiquidacao',
          colunas: ['Situacao'],
          label: 'Encerrado por liquidação',
          valor: '4',
        },
        {
          id: 'EncerradoRecisao',
          colunas: ['Situacao'],
          label: 'Encerrada por recisão',
          valor: '5',
        },
        {
          id: 'EncerradoCancelado',
          colunas: ['Situacao'],
          label: 'Encerrada por cancelamento',
          valor: '11',
        },
        {
          id: 'EncerradoIndeferimento',
          colunas: ['Situacao'],
          label: 'Encerrada por indeferimento',
          valor: '10',
        },
      ],
      titulo: this.$route.params.nome ? this.$route.params.nome : '',
    }
  },
  created() {
  },
}
</script>
